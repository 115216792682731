<template>
  <MultiSelect
    v-if="options"
    v-model="selectedSeller"
    :options="options"
    :show-labels="false"
    :close-on-select="true"
    :allow-empty="false"
    :group-select="false"
    track-by="sellerId"
    @input="changeSeller"
    placeholder="Select a dealership"
    :hide-selected="true"
    label="name"
  >
    <span slot="noResult">No dealerships found</span>
  </MultiSelect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StockviewSellerSelect',
  components: { MultiSelect: () => import('vue-multiselect') },
  data: () => ({ selectedSeller: null }),
  async mounted() {
    if (this.seller) this.selectedSeller = this.seller
  },
  computed: {
    ...mapGetters('stockview/dashboard', ['seller', 'adminSellers']),
    ...mapGetters('auth', ['isAdmin', 'sellers']),
    options() {
      return this.isAdmin ? this.adminSellers : this.sellers
    }
  },
  methods: { ...mapActions('stockview/dashboard', ['changeSeller']) }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
.multiselect
  min-width: 16rem
  max-width: 100%
  .multiselect__option--selected
    background: #ccc
    color: #fff
    &.multiselect__option--highlight
      background: #ccc
      cursor: not-allowed
.multiselect__option
  &.multiselect__option--highlight
    background: $primary
</style>
